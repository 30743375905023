import React from "react"

import Layout from "layout/Layout"
import Container from "layout/Container"
import Collapsible from "elements/Collapsible"

const HelpCenter = props => {
  const { privacyPolicyContent, module } = props.pageContext

  return (
    <Layout seoTitle={module.seoTitle}>
      <Container isCentered>
        <h2>Privacy Policy</h2>
        <hr />
        <div
          className="mb-2"
          dangerouslySetInnerHTML={{
            __html: privacyPolicyContent[0].body.childMarkdownRemark.html.replace(
              /\n/g,
              "<br />"
            ),
          }}
        ></div>
        {privacyPolicyContent.map(faq => {
          return (
            <Collapsible title={faq.question}>
              <div
                dangerouslySetInnerHTML={{
                  __html: faq.answer.childMarkdownRemark.html,
                }}
              ></div>
            </Collapsible>
          )
        })}
      </Container>
    </Layout>
  )
}

export default HelpCenter
